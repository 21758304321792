<template>
  <div class="home">
    <div class="banner">
      <el-carousel height="700px" autoplay>
        <el-carousel-item>
          <img src="@/assets/image/1/lb1.jpg" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="@/assets/image/1/lb2.jpg" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="@/assets/image/1/lb3-zy.jpg" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="@/assets/image/1/lb4-yyy.jpg" />
        </el-carousel-item>
        <el-carousel-item>
          <img src="@/assets/image/1/lb5-slys.jpg" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="aboutUs container">
      <div class="title animated animate-title">
        <div class="en color-b2">ABOUTUS</div>
        <div class="cn color-0243bb">关于我们</div>
      </div>

      <div class="content animated animated-content">
        <div class="img">
          <img src="@/assets/image/1/1 关于我们 555×333px.jpg" />
        </div>
        <div class="right">
          <div style="font-size: 18px">上海微卓医疗科技有限公司</div>
          <div class="color-b2" style="margin-bottom: 30px; font-size: 14px">
            Shanghai Wei zhuo Medical Technology Co., Ltd.
          </div>

          <div style="margin-bottom: 26px">
            上海微卓医疗科技有限公司，成立于2020年，是一家集眼视光产品研发、代理及投资医疗实体的多元化创新公司。公司位于上海静安区核心CBD商圈，核心团队由眼视光领域医生及医疗管理团队组成。
          </div>

          <div style="margin-bottom: 26px">
            2021年创建“MUEYECO”
            品牌，SLOGAN“目之所及，皆为欢喜”，与眼睛相关的，所看到的，感受到的，都能带来愉悦欢喜。主要分为四个系列:
            目爱可系列、禾目达系列、沐悦明系列、星目明系列。
          </div>

          <div>
            每年可服务数以万计的目标客户， 在全国有众多代理商， 有自己的电商渠道和专
            业的服务团队，产品在多个平台均有销售。
          </div>
        </div>
      </div>
    </div>

    <div class="products container">
      <div class="title animated animate-title">
        <div class="en color-b2">PRODUCTS</div>
        <div class="cn color-0243bb">主要产品</div>
      </div>

      <div class="content animated animated-content">
        <div
          class="img"
          @click="goProduct(item.linkType)"
          v-for="(item, index) in productMain"
          :key="index"
        >
          <img :src="item.img" />

          <div class="text">
            <div style="font-size: 16px">{{ item.title }}</div>
            <div style="color: #868686; margin-bottom: 32px">
              {{ item.desc }}
            </div>

            <div class="detail-box">
              了解详情
              <img
                style="width: 30px; margin-left: 146px"
                src="@/assets/image/1/长箭头.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vision container">
      <div class="title animated animate-title">
        <div class="en color-b2">VISION</div>
        <div class="cn color-0243bb">企业愿景</div>
      </div>

      <div class="content animated animated-content">
        <div class="right">
          <div style="margin-bottom: 26px; font-size: 16px">
            以<span style="color: #0243bb">“积微成卓”</span
            >为经营理念，力争将产品从实用性、功能性、有效性、以及服务品质上让客户交口称誉。
          </div>

          <div style="margin-bottom: 26px">
            为眼视光行业内提供产品及服务输出，力争达到专业性、标准化、精简性的使用价值，成为眼视光行业及门诊终端的风向标及引跑者。
          </div>
        </div>

        <div class="img">
          <img src="@/assets/image/1/1 企业愿景 560×310px - 副本.png" />
        </div>
      </div>
    </div>

    <div class="value container">
      <div class="title animated animate-title">
        <div class="en color-b2">VALUE</div>
        <div class="cn color-0243bb">企业价值观</div>
      </div>

      <div class="content animated animated-content">
        <div class="img">
          <div
            class="text"
            style="
              background-image: linear-gradient(
                -90deg,
                rgb(161, 196, 253) 0%,
                rgb(194, 233, 251) 100%
              );
            "
          >
            诚信
          </div>
          <img src="@/assets/image/1/1 企业价值观 240×345px.jpg" />
        </div>

        <div class="img">
          <img src="@/assets/image/1/1 企业价值观2 240×345px.jpg" />
          <div
            class="text"
            style="
              background-image: linear-gradient(
                -90deg,
                rgba(111, 210, 218, 0.99608) 0%,
                rgb(110, 207, 214) 0%,
                rgb(125, 229, 237) 100%
              );
            "
          >
            担当
          </div>
        </div>

        <div class="img">
          <div
            class="text"
            style="
              background-image: linear-gradient(
                0deg,
                rgb(99, 163, 255) 0%,
                rgb(182, 220, 255) 0%,
                rgb(114, 190, 255) 100%
              );
            "
          >
            进取
          </div>
          <img src="@/assets/image/1/1 企业价值观3 240×345px.jpg" />
        </div>

        <div class="img">
          <img src="@/assets/image/1/1 企业价值观4 240×345px.jpg" />
          <div
            class="text"
            style="
              background-image: linear-gradient(
                -90deg,
                rgba(251, 180, 193, 0.99608) 0%,
                rgb(238, 165, 178) 0%,
                rgb(255, 182, 173) 100%
              );
            "
          >
            协同
          </div>
        </div>

        <div class="img">
          <div
            class="text"
            style="
              background-image: linear-gradient(
                -90deg,
                rgb(150, 139, 233) 0%,
                rgb(142, 129, 225) 100%
              );
            "
          >
            创新
          </div>
          <img src="@/assets/image/1/1 企业价值观5 240×345px.jpg" />
        </div>
      </div>
    </div>

    <div class="connection container">
      <div class="title animated animate-title">
        <div class="en color-b2">CONNECTION</div>
        <div class="cn color-0243bb">联系我们</div>
      </div>

      <div class="content animated animated-content">
        <div class="left">
          <div>电话：021-6333-1223 手机：13916102865</div>
          <div>公司地址：上海市静安区江宁路212号10层 C1001、B1011室</div>
          <div>公司邮箱：dingdan@youmujia305.com.cn</div>
        </div>

        <div class="right">
          <div>
            <img src="@/assets/image/1/1 企业公众号 130×130px.jpg" />
            <p>企业公众号</p>
          </div>

          <div>
            <img src="@/assets/image/1/1 商务合作 130×130px.jpg" />
            <p>商务合作</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import Wow from "wow.js";

let router = useRouter();

// 跳转产品页
function goProduct(e) {
  router.push({
    path: "/product",
    query: {
      type: e,
    },
  });
}

// 主要产品
let productMain = reactive([
  {
    img: require("@/assets/image/1/1 明星产品 385×280px.jpg"),
    title: "Mueyeco进口儿童鱼油",
    desc: "高DHA+蓝莓+叶黄素 一瓶抵三瓶营养",
    linkType: 0,
  },
  {
    img: require("@/assets/image/1/1 明星产品2 385×280px.jpg"),
    title: "沐悦明隐形眼镜润滑液",
    desc: "无防腐剂 长期使用无忧",
    linkType: 1,
  },

  {
    img: require("@/assets/image/1/1 明星产品3 385×280px.jpg"),
    title: "沐悦明接触镜无菌生理盐水",
    desc: "无防腐剂 长期使用无忧",
    linkType: 1,
  },

  {
    img: require("@/assets/image/1/1 明星产品4 385×280px.jpg"),
    title: "禾目达真空等离子仪",
    desc: "OK镜护理 等离子镀膜保湿 使用便捷",
    linkType: 2,
  },
  // {
  //   img: require("@/assets/image/1/1 明星产品5 385×280px.jpg"),
  //   title: "星月明一次性遮阳镜",
  //   desc: "术后遮瑕 散瞳遮光",
  // },
  {
    img: require("@/assets/image/1/藻油385x280px.jpg"),
    title: "Mueyeco澳洲进口藏红花酸儿童藻油",
    desc: "藏红花+DHA+叶黄素等独特配方 科学配比",
    linkType: 0,
  },
  {
    img: require("@/assets/image/1/1 明星产品6 385×280px.jpg"),
    title: "禾目达眼科门诊电子软件系统",
    desc: "自主研发 高效稳定 方便快捷",
    linkType: 2,
  },
]);

onMounted(() => {
  // 标题动画
  const wow = new Wow({
    // 对所有拥有该class的元素挂载动画
    boxClass: "animate-title",
    // 需要挂载的动画元素
    animateClass: "animate__lightSpeedInLeft",
    // 偏移值（即偏移一定距离后再执行动画，推荐设置！）
    offset: 80,
    // 移动设备是否生效
    mobile: true,
    // 是否支持异步
    live: true,
  });
  wow.init();

  // 内容动画
  const wow2 = new Wow({
    // 对所有拥有该class的元素挂载动画
    boxClass: "animated-content",
    // 需要挂载的动画元素
    animateClass: "animate__fadeInUp",
    // 偏移值（即偏移一定距离后再执行动画，推荐设置！）
    offset: 80,
    // 移动设备是否生效
    mobile: true,
    // 是否支持异步
    live: true,
  });
  wow2.init();
});
</script>
<style lang="scss" scoped>
.color-b2 {
  color: #b2b2b2;
}

.color-0243bb {
  color: #0243bb;
}

.home {
  padding-bottom: 90px;

  .title {
    margin-bottom: 72px;
    text-align: center;

    .en {
      font-size: 24px;
    }

    .cn {
      font-size: 40px;
      font-weight: bold;
      display: inline-block;
      position: relative;

      &::after {
        content: "";
        height: 2px;
        width: 80%;
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background-color: #999;
      }
    }
  }

  .banner {
    margin-bottom: 46px;

    img {
      width: 100%;
    }
  }

  .aboutUs {
    padding: 0 22px;
    margin-bottom: 82px;

    .content {
      display: flex;
      align-items: center;

      img {
        width: 556px;
      }

      .right {
        background-color: #fff;
        margin-left: 16px;
        padding: 10px;
        font-size: 16px;
        text-align: left;
      }
    }
  }

  .products {
    margin-bottom: 82px;

    .content {
      display: flex;
      flex-wrap: wrap;
      text-align: left;

      .img {
        width: calc(33.333% - 12px);
        margin-right: 18px;
        background-color: #fff;

        img {
          width: 100%;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }

        &:nth-child(-n + 3) {
          margin-bottom: 100px;
        }

        .text {
          padding: 4px 0 20px 34px;
        }

        .detail-box {
          color: #676767;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            transition: all 0.3s linear;
          }

          &:hover {
            img {
              transform: translateX(30px);
            }
          }
        }
      }
    }
  }

  .vision {
    margin-bottom: 82px;

    .content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
    }

    .right {
      padding: 82px 0 82px 38px;
      text-align: left;
    }

    .img {
      img {
        height: 312px;
      }
    }
  }

  .value {
    margin-bottom: 82px;

    .content {
      display: flex;

      .img {
        width: 20%;
        font-size: 0;

        img {
          width: 100%;
        }

        &:nth-child(2n + 1) {
          margin-top: 74px;
        }
      }

      .text {
        font-size: 40px;
        color: #fff;
        height: 74px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .connection {
    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      padding: 40px 100px;

      .left {
        text-align: left;
        font-size: 18px;
        line-height: 50px;
      }

      .right {
        display: flex;

        div {
          &:nth-child(1) {
            margin-right: 70px;
          }

          p {
            font-size: 16px;
          }
        }

        img {
          width: 125px;
        }
      }
    }
  }
}
</style>
