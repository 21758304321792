import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import './assets/css/common.scss';

import 'animate.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'



let app = createApp(App)

app.use(ElementPlus)
app.use(store)
app.use(router)

app.mount('#app')
