<template>
  <nav class="nav-big">
    <div class="container">
      <div class="left">
        <img src="@/assets/image/logo.png" />
      </div>
      <div class="right">
        <div v-for="(item, index) in navArr" :key="index" @click="navigate(index, item)"
          :class="[index == navIndex ? 'active' : '']">{{ item.title }}</div>
      </div>
    </div>
  </nav>
  <router-view />

  <div class="beian-bottom" data-v-45e510d6=""><a href="https://beian.miit.gov.cn/" target="_blank"
      data-v-45e510d6="">备案序号：沪ICP备2021002968号-4</a></div>
</template>

<script setup lang="ts">
import { ref, reactive, toRef, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
let router = useRouter();
let route = useRoute()

let navIndex: any = ref(0);
let navArr: any[] = reactive([
  {
    title: '首页',
    to: '/'
  },
  {
    title: '关于我们',
    to: '/about'
  },
  {
    title: '品牌故事',
    to: '/brand'
  },
  {
    title: '产品介绍',
    to: '/product'
  },
  {
    title: '招商合作',
    to: '/attract'
  },
  {
    title: '联系我们',
    to: '/contact'
  }
])

// 刷新页面导航保持点击
// let currentPath = window.location.href.split('/#')[1]
// let finInd = navArr.findIndex(item => {
//   return item.to == currentPath
// })
// console.log(finInd)
// navIndex.value = finInd

// 切换tab导航
function navigate(index: number, item: any) {
  navIndex.value = index
  router.push({
    path: item.to
  })
}

// 监听路由切换导航
watch(() => route.path, newRoute => {

  console.log(newRoute, 'iii')
  let finInd = navArr.findIndex(item => {
    return item.to == newRoute
  })

  if (finInd != -1) {
    navIndex.value = finInd
  }

})


</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 14px;
  background-color: #fafafa;
}

.container {
  width: 1196px;
  margin: 0 auto
}

.nav-big {
  height: 80px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left {
    margin-right: 270px;

    img {
      width: 136px;
    }
  }

  .right {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;

    div {
      padding: 2px 16px;
      border-radius: 6px;
      cursor: pointer;

      &.active,
      &:hover {
        background-color: #0344bb;
        color: #fff;
      }
    }
  }
}

.beian-bottom {
  padding-bottom: 20px;
  text-align: center;
}
</style>
